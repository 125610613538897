import React from "react"
import { graphql, PageProps } from "gatsby"
import { PrivacyPolicyPageProps } from "../types/pages"
import PageLayout from "../layouts/page-layout"
import PPIContent from "../components/shared/ppi-content"

const PrivacyPolicy: React.FC<PageProps<PrivacyPolicyPageProps>> = ({
  data,
}) => {
  const { leftSide, rightSide } = data?.PAGE_CONTENT?.privacyPolicyOptions

  return (
    <PageLayout seo={data?.PAGE_METADATA?.seo}>
      <div className="page--container">
        <PPIContent
          title={data?.PAGE_METADATA?.title}
          leftContent={leftSide}
          rightContent={rightSide}
        />
      </div>
    </PageLayout>
  )
}

export const query = graphql`
  query ($uri: String!, $locale: String!) {
    PAGE_METADATA: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      id
      slug
      title
      link
      locale {
        locale
      }
      seo {
        metaDesc
        metaKeywords
        canonical
        opengraphDescription
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterTitle
      }
    }
    PAGE_CONTENT: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      privacyPolicyOptions {
        leftSide
        rightSide
      }
    }
  }
`

export default PrivacyPolicy
