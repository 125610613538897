import React from "react"
import "./ppi-content.scss"
import { PPIContentProps } from "../../types/components"

const PPIContent: React.FC<PPIContentProps> = ({
  title,
  leftContent,
  rightContent,
}) => {
  return (
    <div className="ppi-content container--xl">
      <div className="ppi-content__title">
        <h1 data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-out">
          {title}
        </h1>
      </div>
      <div className="ppi-content__containers">
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-easing="ease-out"
          className="ppi-content__containers__left half--left"
          dangerouslySetInnerHTML={{ __html: leftContent }}
        />
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-easing="ease-out"
          className="ppi-content__containers__right half--right"
          dangerouslySetInnerHTML={{ __html: rightContent }}
        />
      </div>
    </div>
  )
}

export default PPIContent
